@keyframes jackpot {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@keyframes quaydeu {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@keyframes bounceInLeft {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes pulse {
  0% {
    transform: scaleX(1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scaleX(1);
  }
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

@keyframes spinloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinlogo {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes stop-color-button-1 {
  100% {
    stop-color: #eccb77;
  }
}

@keyframes stop-color-button-2 {
  100% {
    stop-color: #d6b25c;
  }
}
.animation-button stop:first-child {
  animation: stop-color-button-1 0.6s infinite alternate;
}
.animation-button stop:nth-child(2) {
  animation: stop-color-button-2 0.6s infinite alternate-reverse;
}
.animation-button stop:nth-child(3) {
  animation: stop-color-button-2 0.6s infinite alternate;
}
.animation-button stop:nth-child(4) {
  animation: stop-color-button-2 0.6s infinite alternate;
}
.animation-button stop:nth-child(5) {
  animation: stop-color-button-2 0.9s infinite alternate-reverse;
}

@keyframes bgLinear1 {
  0% {
    background-position: 100% 150%;
  }
  50% {
    background-position: 80% 90%;
  }
  100% {
    background-position: 0% 10%;
  }
}

.anim-bg-linear-1 {
  background-size: 550% 550% !important;
  animation: bgLinear1 2s ease infinite alternate;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-4-19 16:17:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.08);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1);
  }
}

.scaleEffect {
  animation: scale 1.4s ease-in-out infinite alternate;
}

@keyframes shine1 {
  0% {
  }

  60%,
  100% {
    left: 150%;
  }
}

@keyframes shine2 {
  0% {
  }

  60%,
  100% {
    left: 140%;
  }
}

.effects {
  position: absolute;
  height: inherit;
  width: 90%;
  overflow: hidden;
  top: 0;
}

.effects .glassEffect::before {
  content: '';
  position: absolute;
  top: 51%;
  left: -50%;
  width: 5px;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: skewX(-25deg) translateY(-50%);
  animation: shine1 4s infinite;
  border-radius: 20px;
}

.effects .glassEffect::after {
  content: '';
  position: absolute;
  top: 51%;
  left: -100%;
  width: 20%;
  height: 100%;
  background: rgba(255, 255, 255, 0.18);
  transform: skewX(-25deg) translateY(-50%);
  animation: shine2 4s infinite;
}

/* three dots */
.three-dots:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 40px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 40px;
  }
}

@keyframes pop-in {
  0% {
    -ms-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  70% {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  to {
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.pop-in {
  will-change: transform;
  animation-name: pop-in;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.box {
  --main-bg: conic-gradient(
    from var(--border-angle),
    #d6b25c 5%,
    #eccb77 60%,
    #d6b25c 95%
  );

  /* border: solid 0.25px transparent; */

  /* 
  #936321 -10.37%,
  #d6b25c 32.98%,
  #eccb77 51.47%,
  #d6b25c 69.95%,
  #936321 117.12% */

  --gradient-border: conic-gradient(
    from var(--border-angle),
    transparent 25%,
    #936321,
    #d6b25c 99%,
    transparent
  );
  background: var(--main-bg) padding-box, var(--gradient-border) border-box,
    var(--main-bg) border-box;
  background-position: center center;
  animation: bg-spin 0.8s linear infinite;
}
@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

@property --border-angle {
  syntax: '<angle>';
  inherits: true;
  initial-value: 0turn;
}

/* Card Holo Effect */
:root {
  /* --color1: rgb(0, 231, 255);
  --color2: rgb(255, 0, 231); */
  --color1: rgb(255, 255, 255, 0.4);
  --color2: rgba(255, 250, 231, 0.4);
  --color3: rgba(244, 229, 168, 0.3);
  --charizard1: #fac;
  --charizard2: #ddccaa;
}

.card {
  border-radius: 8px;
  /* width: 71.5vw;
  height: 100vw; */
  /* @media screen and (min-width: 600px) {
    width: clamp(12.9vw, 61vh, 18vw);
    height: clamp(18vw, 85vh, 25.2vw);
  } */

  position: relative;
  /* margin: 20px; */
  overflow: hidden;
  z-index: 10;
  /* touch-action: none; */

  /* border-radius: 5% / 3.5%; */
  /* box-shadow: -5px -5px 5px -5px var(--color1), 5px 5px 5px -5px var(--color2); */
  /* -7px -7px 10px -5px transparent, 7px 7px 10px -5px transparent,
    0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5); */
  /* 
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25),
    2px -4px 4px 0px rgba(143, 134, 134, 0.05) inset, 0px 2px 4px 0px #fff inset; */

  transition: transform 0.3s ease, box-shadow 0.2s ease;
  will-change: transform, filter;

  /* background-color: #040712; */
  background-image: var(--front);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transform-origin: center;
}

.card:hover {
  box-shadow: -20px -20px 30px -25px var(--color3),
    20px 20px 30px -25px var(--color3), -7px -7px 10px -5px var(--color3),
    7px 7px 10px -5px var(--color3), 0 0 13px 4px rgba(255, 255, 255, 0.3),
    0px 4px 8px 0px rgba(0, 0, 0, 0.25),
    2px -4px 4px 0px rgba(0, 0, 0, 0.05) inset, 0px 2px 4px 0px #fff inset;
  /* 0 55px 35px -20px rgba(0, 0, 0, 0.5); */
}

.card.charizard {
  --color1: var(--charizard1);
  --color2: var(--charizard2);
}
.card.charizard img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.card:before,
.card:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-repeat: no-repeat;
  opacity: 0.5;
  mix-blend-mode: color-dodge;
  transition: all 0.33s ease;
}

.card:before {
  background-position: 50% 50%;
  background-size: 300% 300%;
  opacity: 0.9;
  filter: brightness(0.9) contrast(1);
  z-index: 1;
  border-radius: 8px;
}

.card:after {
  opacity: 1;
  background-position: 50% 50%;
  background-size: 160%;
  background-blend-mode: overlay;
  z-index: 2;
  filter: brightness(1) contrast(1);
  transition: all 0.33s ease;
  mix-blend-mode: color-dodge;
  opacity: 0.95;
  border-radius: 8px;
}

.flash {
  position: relative;
  overflow: hidden;
  z-index: 10;
  transition: transform 0.3s ease, box-shadow 0.2s ease;
  will-change: transform, filter;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transform-origin: center;
}

.flash::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 24px;
  background-repeat: no-repeat;
  opacity: 0.5;
  mix-blend-mode: color-dodge;
  transition: all 0.33s ease;

  background-position-x: 100%;

  background-size: 300% 300%;

  background-image: linear-gradient(
    115deg,
    transparent 0%,
    var(--color1) 25%,
    transparent 47%,
    transparent 53%,
    var(--color2) 75%,
    transparent 100%
  );
  opacity: 0.5;
  filter: brightness(0.5) contrast(1);
  z-index: 1;
  border-radius: 8px;
  pointer-events: none;
  animation: flashGradient 1.25s 1s linear forwards;
}
@keyframes flashGradient {
  100% {
    background-position-x: -100%;
    opacity: 1;
    filter: brightness(0.65) contrast(1.2);
  }
}

.card.active:after,
.card:hover:after {
  filter: brightness(1) contrast(1);
  opacity: 1;
}

.card.active,
.card:hover {
  animation: none;
  transition: box-shadow 0.1s ease-out;
}

.card.active:before,
.card:hover:before {
  animation: none;
  background-image: linear-gradient(
    110deg,
    transparent 25%,
    var(--color1) 48%,
    var(--color2) 52%,
    transparent 75%
  );
  background-position: 50% 50%;
  background-size: 250% 250%;
  opacity: 0.88;
  filter: brightness(0.1) contrast(0.1);
  transition: none;
}

.card.active:before,
.card:hover:before,
.card.active:after,
.card:hover:after {
  animation: none;
  transition: none;
}

.card.animated {
  /* transition: none; */
  /* animation: holoCard 3s ease 0s 1; */
  animation-delay: 1.5s;
}
.card.animated:before {
  transition: none;
  /* animation: holoGradient 3s ease 0s 1; */
  animation-delay: 1.5s;
}
.card.animated:after {
  transition: none;
  /* animation: holoSparkle 3s ease 0s 1; */
  animation-delay: 1.5s;
}

@keyframes holoSparkle {
  0%,
  100% {
    opacity: 0.75;
    background-position: 50% 50%;
    filter: brightness(1.2) contrast(1.25);
  }
  5%,
  8% {
    opacity: 1;
    background-position: 40% 40%;
    filter: brightness(0.8) contrast(1.2);
  }
  13%,
  16% {
    opacity: 0.5;
    background-position: 50% 50%;
    filter: brightness(1.2) contrast(0.8);
  }
  35%,
  38% {
    opacity: 1;
    background-position: 60% 60%;
    filter: brightness(1) contrast(1);
  }
  55% {
    opacity: 0.33;
    background-position: 45% 45%;
    filter: brightness(1.2) contrast(1.25);
  }
}

@keyframes holoGradient {
  /* 0%,
  100% {
    opacity: 0.5;
    background-position: 50% 50%;
    filter: brightness(0.5) contrast(1);
  }
  5%,
  9% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(0.75) contrast(1.25);
  } */
  /* 13%,
  17% {
    background-position: 0% 0%;
    opacity: 0.88;
  } */
  /* 35%, */
  /* 0% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(0.4) contrast(1);
  } */
  55% {
    background-position: 0 0;
    opacity: 1;
    filter: brightness(0.75) contrast(1.25);
  }
}

@keyframes holoCard {
  /* 0%,
  100% {
    transform: rotateZ(0deg) rotateX(0deg) rotateY(0deg);
  }
  5%, */
  /* 8% {
    transform: rotateZ(0deg) rotateX(6deg) rotateY(-20deg);
  }
  13%,
  16% {
    transform: rotateZ(0deg) rotateX(-9deg) rotateY(32deg);
  } */
  /* 35%, */
  38% {
    transform: rotateZ(1deg) rotateX(-1deg) rotateY(-1deg);
  }
  /* 55% {
    transform: rotateZ(-3deg) rotateX(-12deg) rotateY(-27deg);
  } */
}

.svg-color {
  fill: #c00b0b;
  animation: filter-animation 1s infinite alternate;
}

@keyframes filter-animation {
  0% {
    fill: #ff555f;
  }
  33% {
    fill: #c00b0b;
  }
  66% {
    fill: #ff555f;
  }
  100% {
    fill: #c00b0b;
  }
}
@keyframes moveGradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}

.bg-linear-8-ani {
  /* Điều chỉnh theo nhu cầu */
  position: relative;
  overflow: hidden;

  animation: boxShadow 2s linear infinite;
}
/* 
 @keyframes boxShadow {
   0% {
     box-shadow: rgba(0, 0, 0, 0.05) 2px -4px 4px 0px inset,
       rgb(255, 255, 255,.5) -2px 4px 4px 0px inset;
   }

   25% {
     box-shadow: rgba(0, 0, 0, 0.05) 4px 2px 4px 0px inset,
       rgb(255, 255, 255,.5) -4px -2px 4px 0px inset;
   }

   50% {
     box-shadow: rgba(0, 0, 0, 0.05) -2px 4px 4px 0px inset,
       rgb(255, 255, 255,.5) 2px -4px 4px 0px inset;
   }

   75% {
     box-shadow: rgba(0, 0, 0, 0.05) -4px -2px 4px 0px inset,
       rgb(255, 255, 255,.5) 4px 2px 4px 0px inset;
   }

   100% {
     box-shadow: rgba(0, 0, 0, 0.05) 2px -4px 4px 0px inset,
       rgb(255, 255, 255,.5) -2px 4px 4px 0px inset;
   }
 } */
@keyframes boxShadow {
  0%,
  100% {
    box-shadow: rgba(0, 0, 0, 0.05) 2px -4px 4px 0px inset,
      rgba(255, 255, 255, 0.5) -2px 4px 4px 0px inset;
  }

  25% {
    box-shadow: rgba(0, 0, 0, 0.05) 4px 2px 4px 0px inset,
      rgba(255, 255, 255, 0.5) -4px -2px 4px 0px inset;
  }

  50% {
    box-shadow: rgba(0, 0, 0, 0.05) -2px 4px 4px 0px inset,
      rgba(255, 255, 255, 0.5) 2px -4px 4px 0px inset;
  }

  75% {
    box-shadow: rgba(0, 0, 0, 0.05) -4px -2px 4px 0px inset,
      rgba(255, 255, 255, 0.5) 4px 2px 4px 0px inset;
  }
}

.light {
  clip-path: circle(50%);
  overflow: hidden;
  border-radius: 999px;
}
.light::before {
  clip-path: ellipse(250% 550% at 50% 50%);
  content: '';
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  top: -5%;
  animation: lightMove 10s 0s infinite linear alternate-reverse;
}

@keyframes lightMove {
  0%,
  100% {
    top: -5%;
    opacity: 1;
  }

  8.33% {
    /* 0.5s */
    top: 100%;
    opacity: 1;
  }

  16.67% {
    /* 1s */
    top: 100%;
    opacity: 0;
  }

  83.33% {
    /* 5s */
    top: 100%;
    opacity: 0;
  }

  91.67% {
    /* 5.5s */
    top: -5%;
    opacity: 0;
  }

  100% {
    top: -5%;
    opacity: 1;
  }
}

.sport-card {
  filter: drop-shadow(0px -4px 4px #ffeb84);
}
.sport-card:hover {
  filter: drop-shadow(0px -4px 4px #ffeb84);
  animation: sportCard 1s infinite alternate;
}

@keyframes sportCard {
  100% {
    filter: drop-shadow(0px -4px 0px #ffeb84);
  }
  0% {
    filter: drop-shadow(0px -4px 8px #ffeb84);
  }
}
.path-animation {
  animation: pathAnimation 4s linear infinite alternate;
}

@keyframes pathAnimation {
  0% {
    stroke: #aa7300;
  }
  24% {
    stroke: #d3a300;
  }
  50% {
    stroke: #b67000;
  }
  77% {
    stroke: #e5b900;
  }
  100% {
    stroke: #bb7000;
  }
}

.strokeBet:hover svg:nth-child(1) {
  animation: scale1 0.5s forwards !important;
}
/* .strokeBet svg {
  animation: scale1 0.5s 2s;
} */
.strokeBet:hover svg:nth-child(2) {
  animation: scale1 0.5s forwards !important;
}
/* .strokeBet svg:nth-child(2) {
  animation: scale1 0.5s 2s;
  transition: 0.4s all;
} */
.strokeBet:hover svg:nth-child(3) {
  animation: scale1 0.5s forwards !important;
}
/* .strokeBet svg:nth-child(3) {
  animation: scale1 0.5s 2s;
  transition: 0.4s all;
} */
.strokeBet:hover svg:nth-child(4) {
  animation: scale1 0.5s forwards !important;
}
/* .strokeBet svg:nth-child(4) {
  animation: scale1 0.5s 2s;
  transition: 0.4s all;
} */
@keyframes scale1 {
  0% {
    transform: scale(1.2);
  }
  /* 30%, */
  50% {
    transform: scale(1.4);
  }

  40%,
  80% {
    transform: scale(1.6);
  }
  100% {
    transform: scale(1.3);
  }
}

@keyframes skeleton {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@keyframes marqueeEffect {
  0% {
    transform: translateX(-10%);
  }
  50% {
    transform: translateX(-33%);
  }
  100% {
    transform: translateX(-10%);
  }
}

.marqueeEffect {
  display: inline-block;
  white-space: nowrap;
  animation: marqueeEffect 15s linear infinite;
}

@-webkit-keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}
@keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}

/* 13 */
.btn-13 {
  /* background-color: #89d8d3; */
  /* background-image: linear-gradient(315deg, #474747 0%, #303030 74%); */
  border: none;
  z-index: 1;
}
.btn-13:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 5px;
  /* background-color: #4dccc6; */
  /* background-image: linear-gradient(315deg, #323434 0%, #101515 74%); */
  /* box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001; */
  transition: all 0.3s ease;
}
.btn-13:hover {
  color: #fff;
}
.btn-13:hover:after {
  top: 0;
  height: 100%;
}
.btn-13:active {
  top: 2px;
}

.cardHolo:hover {
  transition: 0.25s all;
  box-shadow: 0px 0px 20px 3px rgba(234, 200, 116, 0.5);
}

.cardHolo:hover img {
  transition: 0.2s all;
  transform: scale(1.25);
  filter: brightness(0.9);
}
.cardHolo img {
  transition: 0.2s all;
}

/* HEART ANIMATION SPRITE */

.heartContainer {
  background-image: url('/img/common/web_heart_animation.png');
  background-repeat: no-repeat;
  background-size: 2900%;
  background-position: left;
  margin: 0 auto;
  cursor: pointer;
}

.heartAnimated {
  animation: heart-burst 0.8s steps(28) forwards;
}

@keyframes heart-burst {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

/* HEART ANIMATION */
[id='toggle-heart'] {
  position: absolute;
  left: -100vw;
}
[id='toggle-heart']:checked + label {
  color: #e2264d;
  will-change: font-size;
  animation: heart 1s cubic-bezier(0.17, 0.89, 0.32, 1.49);
}
[id='toggle-heart']:checked + label:before,
[id='toggle-heart']:checked + label:after {
  animation: inherit;
  animation-timing-function: ease-out;
}
[id='toggle-heart']:checked + label:before {
  will-change: transform, border-width, border-color;
  animation-name: bubble;
}
[id='toggle-heart']:checked + label:after {
  will-change: opacity, box-shadow;
  animation-name: particles;
}

[for='toggle-heart'] {
  align-self: center;
  position: relative;
  color: #aab8c2;
  font-size: 2em;
  user-select: none;
  cursor: pointer;
}
[for='toggle-heart']:before,
[for='toggle-heart']:after {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  content: '';
}
[for='toggle-heart']:before {
  box-sizing: border-box;
  margin: -2.25rem;
  border: solid 2.25rem #e2264d;
  width: 4.5rem;
  height: 4.5rem;
  transform: scale(0);
}
[for='toggle-heart']:after {
  margin: -0.1875rem;
  width: 0.375rem;
  height: 0.375rem;
  box-shadow: 0.32476rem -3rem 0 -0.20625rem #ff8080,
    -0.32476rem -2.625rem 0 -0.20625rem #ffed80,
    2.54798rem -1.61656rem 0 -0.20625rem #ffed80,
    1.84982rem -1.89057rem 0 -0.20625rem #a4ff80,
    2.85252rem 0.98418rem 0 -0.20625rem #a4ff80,
    2.63145rem 0.2675rem 0 -0.20625rem #80ffc8,
    1.00905rem 2.84381rem 0 -0.20625rem #80ffc8,
    1.43154rem 2.22414rem 0 -0.20625rem #80c8ff,
    -1.59425rem 2.562rem 0 -0.20625rem #80c8ff,
    -0.84635rem 2.50595rem 0 -0.20625rem #a480ff,
    -2.99705rem 0.35095rem 0 -0.20625rem #a480ff,
    -2.48692rem 0.90073rem 0 -0.20625rem #ff80ed,
    -2.14301rem -2.12438rem 0 -0.20625rem #ff80ed,
    -2.25479rem -1.38275rem 0 -0.20625rem #ff8080;
}

@keyframes heart {
  0%,
  17.5% {
    font-size: 0;
  }
}
@keyframes bubble {
  15% {
    transform: scale(1);
    border-color: #cc8ef5;
    border-width: 2.25rem;
  }
  30%,
  100% {
    transform: scale(1);
    border-color: #cc8ef5;
    border-width: 0;
  }
}
@keyframes particles {
  0%,
  20% {
    opacity: 0;
  }
  25% {
    opacity: 1;
    box-shadow: 0.32476rem -2.4375rem 0 0rem #ff8080,
      -0.32476rem -2.0625rem 0 0rem #ffed80,
      2.1082rem -1.26585rem 0 0rem #ffed80,
      1.41004rem -1.53985rem 0 0rem #a4ff80,
      2.30412rem 0.85901rem 0 0rem #a4ff80, 2.08305rem 0.14233rem 0 0rem #80ffc8,
      0.76499rem 2.33702rem 0 0rem #80ffc8, 1.18748rem 1.71734rem 0 0rem #80c8ff,
      -1.35019rem 2.0552rem 0 0rem #80c8ff,
      -0.60229rem 1.99916rem 0 0rem #a480ff,
      -2.44865rem 0.22578rem 0 0rem #a480ff,
      -1.93852rem 0.77557rem 0 0rem #ff80ed,
      -1.70323rem -1.77366rem 0 0rem #ff80ed,
      -1.81501rem -1.03204rem 0 0rem #ff8080;
  }
}
