@tailwind base;
@tailwind components;
@tailwind utilities;

@import './core.css';
@import './hover.css';
@import './responsive';
@import './animation.css';

html,
body {
  height: 100%;
  width: 100%;
  position: relative;
  overscroll-behavior-x: none;
  -webkit-overscroll-behavior-x: none;
}

/* Hide scrollbar for all browsers */
html {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

html::-webkit-scrollbar {
  width: 0px; /* Safari and Chrome */
  background: transparent; /* Optional: just make scrollbar invisible */
}

* {
  cursor: url('/img/common/mouse-cursor.svg'), default;
}

* button,
* a,
* a *,
* button *,
.cursor-hand {
  cursor: url('/img/common/hand-cursor.svg'), default !important;
}

@layer utilities {
  .cursor-hand * {
    cursor: url('/img/common/hand-cursor.svg'), default !important;
  }
  .cursor-mouse,
  .cursor-mouse *,
  .cursor-mouse:hover,
  .cursor-mouse:hover *,
  .cursor-mouse *:hover {
    cursor: url('/img/common/mouse-cursor.svg'), default !important;
  }

  .container-pc {
    width: 100%;
    max-width: 90rem;
    margin: 0 auto;
    /* background: #eaeaea; */
  }

  .container-profile {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
  }

  .main-content {
    min-height: calc(100vh - 360px);
  }
  .layout-content {
    min-height: calc(100vh - 687px);
  }
}

@layer components {
  .animate {
    display: flex;
    animation: scroll 20s linear infinite;
  }
  .animate:hover {
    animation-play-state: paused;
  }

  .animate-reversed {
    display: flex;
    animation: scroll 20s linear infinite;
    animation-direction: reverse;
  }
  .animate-reversed:hover {
    animation-play-state: paused;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translate(-50%);
    }
  }
}

@media (min-width: 768px) {
  .my-class {
    font-size: 18px;
  }
}
