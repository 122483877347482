/* Màn hình nhỏ hơn hoặc bằng 576px */
@media (max-width: 768px) {
  /* Đặt các styles cho màn hình 'md' ở đây */
  body {
    /* background: #eaeaea; */
  }
}

/* iPhone X, XS, 11 Pro, 12 mini, 13 mini */
/* @media only screen and (max-device-width: 812px) and (orientation: portrait) {
  footer {
    padding-bottom: 40px;
  }
} */
/* iPhone XR, 11, 11 Pro Max, 12, 12 Pro, 13, 13 Pro */
/* @media only screen and (max-device-width: 896px) and (orientation: portrait) {
  footer {
    padding-bottom: 30px; 
  }
} */

@media (max-height: 1280px) {
  .layout-content {
    min-height: calc(100vh - 500px) !important;
  }
}
