.swiper * {
  cursor: url('/img/common/hand-cursor.svg'), default !important;
}

.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-left {
  position: absolute;
  border-radius: 50%;
  background-image: none;
}

.topSliderHorizontalGiftCode {
  left: 261px !important;
  bottom: 13.5% !important;
  text-align: left;
  width: 100px !important;
  z-index: 999 !important;
}

.topSliderBullet {
  background: rgba(255, 255, 255, 0.25) !important;
  width: 12px !important;
  height: 12px !important;
  display: inline-block;
  border-radius: 10px !important;
  transition: 0.25s all;
  margin: 0 3px !important;
}
.topSliderBulletActive {
  width: 56px !important;
  height: 12px !important;
  background: rgba(255, 255, 255, 0.5) !important;
  border-radius: 10px !important;
  display: inline-block;

  transition: 0.25s all;
}

.bulletActiveGiftCode {
  background: rgba(184, 142, 66, 0.8) !important;
  width: 60px !important;
  height: 12px !important;
  border-radius: 8px !important;
  display: inline-block;
  transition: 0.25s all;
}

.bulletInActiveGiftCode {
  background: rgba(231, 198, 113, 0.3);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  transition: 0.25s all;
  cursor: pointer;
}

.horizontalGiftCode {
  left: 0;
  z-index: 4;
  bottom: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}

.matchSliderHorizontal {
  width: 100%;
  bottom: 3vw !important;
}

.matchSliderBulletActive {
  transition: background 0.5s;
  width: 4.5vw !important;
  height: 4.5vw !important;
  top: 0.5vw;
  display: inline-block;
  border-radius: 50%;
  background: linear-gradient(
    75deg,
    #936321 -10.37%,
    #d6b25c 32.98%,
    #eccb77 51.47%,
    #d6b25c 69.95%,
    #936321 117.12%
  ) !important;
}
.matchSliderBulletActive::after {
  display: none;
}

.matchSliderBullet {
  transition: background 0.5s;
  width: 1vw;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  margin: auto;
  background-clip: padding-box !important;
  padding: 0.85vw;
  border: solid 1vw transparent;
  background: #eaeaea;
}
.matchSliderBullet::after {
  color: white;
  border-radius: 50%;
  margin: auto;
  content: '';
  position: absolute;
  background: linear-gradient(
    75deg,
    #936321 -10.37%,
    #d6b25c 32.98%,
    #eccb77 51.47%,
    #d6b25c 69.95%,
    #936321 117.12%
  );
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -0.3vw;
  z-index: -1;
}
.swiper-panorama {
  overflow: visible;
}

.swiper-gl {
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
}

.swiper-gl .swiper-slide,
.swiper-gl swiper-slide {
  position: relative;
}

.swiper-gl .swiper-gl-image {
  display: none;
}

/* Đặt lớp cho phần tử chứa */
.swiper-gl > canvas {
  position: relative;
  width: 100%;
}

/* Áp dụng cho màn hình từ kích thước medium trở lên */
@media (min-width: 768px) {
  .swiper-gl > canvas {
    width: auto;
  }

  .swiper-gl > canvas::before,
  .swiper-gl > canvas::after {
    position: absolute;
    top: 0;
    height: 100%;
    width: 40px;
    content: '';
    background: 100% 100%;
    z-index: 40;
  }

  .swiper-gl > canvas::before {
    right: 0;
    background: linear-gradient(to left, transparent, black);
  }

  .swiper-gl > canvas::after {
    left: 0;
    background: linear-gradient(to right, transparent, black);
  }
}

.swiper-gl > canvas {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 1440px;
  /* width: 100%; */
  height: 100%;
  margin: auto;
  pointer-events: none;
  /* bg-[url("/img/banner/background.png")] */
}
.swiper.swiper-gl {
  background: url('/img/banner/background.png') center center no-repeat;
  background-size: 100% 100%;
}

.swiper-gl-image {
  /* width: 100%;
    height: 100%; */
  /* object-fit: cover; */
  /* object-position: center;
    position: absolute;
    left: 0;
    top: 0 */
}

.swiper-shutters .swiper-slide,
.swiper-shutters swiper-slide {
  overflow: hidden;
}

.swiper-shutters-image,
.swiper-shutters-image-clones,
.swiper-shutters-image-clone {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.swiper-shutters-image {
  object-fit: cover;
  object-position: center;
}

.swiper-shutters-image-clone {
  overflow: hidden;
}

.swiper-cube {
  overflow: visible;
}

.swiper-cube .swiper-slide {
  pointer-events: none;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-cube.swiper-rtl .swiper-slide {
  transform-origin: 100% 0;
}

.swiper-cube .swiper-slide-active,
.swiper-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-cube .swiper-slide-active,
.swiper-cube .swiper-slide-next,
.swiper-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible;
}

.swiper-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  z-index: 0;
}

.swiper-cube .swiper-cube-shadow:before {
  content: '';
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-filter: blur(50px);
  filter: blur(50px);
}

.swiper-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}

.swiper-cube .swiper-slide-shadow-cube.swiper-slide-shadow-bottom,
.swiper-cube .swiper-slide-shadow-cube.swiper-slide-shadow-left,
.swiper-cube .swiper-slide-shadow-cube.swiper-slide-shadow-right,
.swiper-cube .swiper-slide-shadow-cube.swiper-slide-shadow-top {
  z-index: 0;
  backface-visibility: hidden;
}

.swiper-slicer {
  position: relative;
  perspective: 1200px;
}

.swiper-slicer .swiper {
  perspective: none;
  transform-style: preserve-3d;
}

.swiper-slicer .swiper-slicer-size {
  width: 100%;
  height: auto;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  display: block;
}

.swiper-slicer .swiper-slide {
  background-size: 100% auto;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.swiper-slicer .swiper:nth-child(1) .swiper-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 25%;
}

.swiper-slicer .swiper:nth-child(n + 2) {
  pointer-events: none;
  position: absolute;
  left: 0;
  width: 100%;
  height: 25%;
}

.swiper-slicer .swiper:nth-child(n + 2) .swiper-slide {
  pointer-events: none;
}

.swiper-slicer .swiper:nth-child(2) {
  top: 25%;
}

.swiper-slicer .swiper:nth-child(2) .swiper-slide {
  background-position: 0 -100%;
}

.swiper-slicer .swiper:nth-child(3) {
  top: 50%;
}

.swiper-slicer .swiper:nth-child(3) .swiper-slide {
  background-position: 0 -200%;
}

.swiper-slicer .swiper:nth-child(4) {
  top: 75%;
}

.swiper-slicer .swiper:nth-child(4) .swiper-slide {
  background-position: 0 -300%;
}
