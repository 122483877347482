@tailwind utilities;

.switch-eng-lng.ant-switch .ant-switch-handle::before,
.switch-th-lng.ant-switch .ant-switch-handle::before {
  display: none;
}
.switch-eng-lng.ant-switch .ant-switch-inner,
.switch-th-lng.ant-switch .ant-switch-inner {
  padding-inline-end: 0px !important;
  padding-inline-start: 9px !important;
}

.ant-switch * {
  cursor: url('/img/common/hand-cursor.svg'), default !important;
}
@layer utilities {
  .primary-btn.ant-btn {
    background: #d3a300;
    border-radius: 24px;
    height: 40px;
    font-weight: bold;
  }

  .primary-btn.ant-btn:not(:disabled):not(.ant-btn-disabled):hover {
    box-shadow: 0px 0px 4px 1px rgba(234, 200, 116, 0.5) !important;
    background: #a98200;
    opacity: 0.9 !important;
  }
  .secondary-btn.ant-btn {
    background: #845d00;
    border-radius: 24px;
    height: 40px;
    color: #fff;
    font-weight: bold;
  }

  .secondary-btn.ant-btn:not(:disabled):not(.ant-btn-disabled):hover {
    box-shadow: 0px 0px 4px 1px rgba(234, 200, 116, 0.5) !important;
    background: #a98200;
    opacity: 0.9 !important;
  }

  .primary-btn {
    position: relative;
    background: #d3a300;
    border-radius: 24px;
    height: 40px;
    font-weight: bold;
    color: #fff;
  }

  .primary-btn:not(:disabled):hover {
    box-shadow: 0px 0px 4px 1px rgba(234, 200, 116, 0.5) !important;
    background: #a98200;
    opacity: 0.9 !important;
  }
  .secondary-btn {
    background: #845d00;
    border-radius: 24px;
    height: 40px;
    color: #fff;
    font-weight: bold;
  }

  .secondary-btn:not(:disabled):hover {
    box-shadow: 0px 0px 4px 1px rgba(234, 200, 116, 0.5) !important;
    background: #a98200;
    opacity: 0.9 !important;
  }
}
.small-btn.ant-btn {
  /* height: 24px; */
}

.middle-btn.ant-btn {
  /* height: 24px; */
}

.menuMobile * {
  cursor: url('/img/common/hand-cursor.svg'), default !important;
}

.menuMobile .ant-menu-item-active,
.menuMobile .ant-menu-item-selected,
.menuMobile .ant-menu-submenu-active,
.menuMobile .ant-menu-submenu-selected > * {
  color: #845d00 !important;
}
.menuMobile .ant-menu-title-content {
  font-weight: bold;
}

.tabAccountInfo.ant-tabs .ant-tabs-nav-list * {
  color: #000 !important;
  cursor: url('/img/common/hand-cursor.svg'), default !important;
}
.tabAccountInfo .ant-tabs-tab-btn {
  font-size: 3.05vw !important;
}
.tabAccountInfo .ant-tabs-nav {
  margin: 0 !important;
}

.tabAccountInfo.ant-tabs .ant-tabs-nav {
  width: 100%;
  border-radius: 8px 8px 0px 0px !important;
  background: linear-gradient(
    140.58deg,
    #e1e1e1 3.93%,
    #ffffff 128.71%
  ) !important;
}
.tabAccountInfo.ant-tabs .ant-tabs-nav-wrap {
  border-radius: 8px 8px 0px 0px !important;
  display: block !important;
}
.tabAccountInfo.ant-tabs .ant-tabs-tab {
  text-align: center;
  border: none !important;
  border-radius: 0 !important;
  margin: 0 !important;
  /* font-size: 12px !important; */
  font-size: initial;
  width: 100% !important;
  /* min-width: 100%; */
  text-align: center !important;
  justify-content: center;
}
.tabAccountInfo.ant-tabs .ant-tabs-nav-operations {
  display: none !important;
}

.tabAccountInfo.ant-tabs .ant-tabs-tab-active {
  background: #e1c978 !important;
  box-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0px 0px !important;
}
.tabAccountInfo.ant-tabs .ant-tabs-nav-list * {
  color: rgba(0, 0, 0, 0.65) !important;
}
.tabAccountInfoReset.ant-tabs .ant-tabs-nav-list > .ant-tabs-tab {
  margin-bottom: 8px !important;
  background: #fcfcfc;
}

.tabAccountInfoReset.ant-tabs .ant-tabs-nav-list {
  background: #fcfcfc;
}
.tabAccountInfoReset.ant-tabs
  > div.ant-tabs-nav
  > div.ant-tabs-nav-wrap
  > div
  > div:nth-child(4) {
  margin-top: 8px !important;
  border-top: 1px solid #efefef !important;
}
.tabAccountInfoReset.ant-tabs
  > div.ant-tabs-nav
  > div.ant-tabs-nav-wrap
  > div
  > div:nth-child(4)::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  /* border-top: 1px solid #efefef !important; */
}

.tabAccountInfoDesktop .ant-tabs-tab-btn {
  font-size: 16px !important;
}
.tabAccountInfoReset.ant-tabs .ant-tabs-tab:hover {
  background: #e1c978 !important;
  box-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px !important;
}

.tabAccountInfoReset.ant-tabs .ant-tabs-tab {
  justify-content: start;
  height: 35px !important;
  cursor: url('/img/common/hand-cursor.svg'), default !important;
}
.tabAccountInfoReset.ant-tabs .ant-tabs-tab-btn {
  font-size: 16px !important;
}
.tabAccountInfoReset.ant-tabs .ant-tabs-tab-active {
  background: rgba(225, 201, 120, 0.5) !important;
  border-radius: 8px !important;
  border: 0 !important;
}
.tabAccountInfoReset.ant-tabs .ant-tabs-nav {
  text-align: left !important;
  background: #fff !important;
  border-radius: 0 !important;
}

.ant-pagination .ant-pagination-item.ant-pagination-item-active {
  border-color: #d3a300 !important;
  border-radius: 8px !important;
  min-width: 28px !important;
  min-height: 24px !important;
  width: fit-content !important;
  height: fit-content !important;
}
.ant-pagination .ant-pagination-item.ant-pagination-item-active a {
  color: #d3a300 !important;
  min-width: 28px !important;
  min-height: 24px !important;
  width: fit-content !important;
  height: fit-content !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.ant-btn .wave-motion,
.ant-btn .wave-motion-appear,
.ant-btn .wave-motion-appear-active {
  outline: 4px solid #d3a300 !important;
  /* box-shadow: 1px 1px #d3a300 !important; */
}

.ant-radio .ant-radio-inner {
  border-color: #d3a300 !important;
  background: #fff !important;
}
.ant-radio .ant-radio-inner::after {
  transform: scale(0.5) !important;
  background: #d3a300 !important;
}
.tokenRadioGroup > label {
  position: relative;

  min-height: 80px !important;
}
.tokenRadioGroup > label > span.ant-radio {
  position: absolute !important;
  top: 8px;
  left: 8px;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector,
.ant-select-selection-search-input,
#bankCode {
  /* border-color: #d9d9d9 !important; */
  box-shadow: none !important;
}

.ant-select-open #bankCode {
  /* padding-left: 26px; */
}

span.ant-spin-dot.ant-spin-dot-spin i {
  background: #d4a302 !important;
}

.hot-game-select .ant-select-selector:focus,
.hot-game-select .ant-select-selector {
  border-radius: 8px !important;
  /* background: #d6b25c !important; */
  border: none !important;
}

.hot-game-select .logo {
  /* display: none; */
}
.hot-game-select .ant-select-selector {
  color: #e1c978 !important;
  border-radius: 23px !important;
  background: radial-gradient(
    143.73% 114.62% at 50.16% -7.95%,
    #d3a300 0%,
    #796843 0.01%,
    #2d2925 100%,
    #2d2925 100%
  ) !important;
  border: 1px solid #e1c978 !important;
}

.hot-game-select.ant-select-open .ant-select-selector {
  color: #e1c978 !important;
  background: #291f00 !important;
  text-transform: capitalize !important;
}
.hot-game-select .ant-select-selection-item {
  text-transform: capitalize !important;
}
.hot-game-select.ant-select-open
  .ant-select-selector
  .ant-select-selection-item,
.hot-game-select.ant-select-focused.ant-select-single
  span.ant-select-selection-item
  span {
  color: #e1c978 !important;
}

.gameDropdown {
  background: radial-gradient(
    143.73% 114.62% at 50.16% -7.95%,
    #d3a300 0%,
    #796843 0.01%,
    #2d2925 100%,
    #2d2925 100%
  );
  color: #fff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
}
.gameDropdown .ant-select-item.ant-select-item-option:hover {
  background: rgba(0, 0, 0, 0.2) !important;
}
.gameDropdown
  .ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected,
.ant-select-item-option-active {
  background: rgba(0, 0, 0, 0.2) !important;
  font-weight: normal !important;
}
.ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected:hover {
  font-weight: 600 !important;
  transition: 0.2s all;
}
.ant-select-item-option-active:hover {
  font-weight: normal !important;
}
.gameDropdown .ant-select-item.ant-select-item-option {
  color: #fff !important;
  background: rgba(0, 0, 0, 0.04) !important;
}

.gameSelectSearch .ant-select-selector {
  border-radius: 26px !important;
  border-color: rgba(0, 0, 0, 0.05) !important;
  padding-left: 24px !important;
}
.gameSelectSearch .ant-select-selector input {
  padding-left: 12px !important;
}

.gameSelectDropdown {
  z-index: 9999999999 !important;
}

.gameSelectDropdown .rc-virtual-list-holder-inner *:hover {
  cursor: url('/img/common/hand-cursor.svg'), default !important;
}

.kycUpload .ant-upload.ant-upload-select {
  width: 100% !important;
  margin: 0 !important;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  min-height: 110px !important;
}
.kycUpload .ant-upload-list-item-container,
.kycUpload .ant-upload-list-item.ant-upload-list-item-done {
  width: 100% !important;
  margin: 0 !important;
  min-height: 120px !important;
}
.kycUpload {
  transition: all 0s !important;
}

.kycUpload .ant-upload-list.ant-upload-list-picture-card {
  min-height: 120px !important;
}

.gameDropdown .rc-virtual-list,
.gameDropdown .rc-virtual-list .rc-virtual-list-holder,
.gameDropdown .rc-virtual-list * {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.gameDropdown .rc-virtual-list .rc-virtual-list-holder {
  max-height: 44vh !important;
}

.wrapLargeSmall {
  inset: unset !important;
}

.gameContainerModal,
.gameContainerModal-wrap {
  pointer-events: all !important;
}

.ant-input-outlined.ant-input-disabled.ant-input-outlined[disabled] {
  background: #acacac !important;
}
