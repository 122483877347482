@tailwind utilities;

a {
  color: #000 !important;
}

a:active,
a:hover {
  color: #d3a300 !important;
}

/*bg-linear*/
@layer utilities {
  .bg-linear {
    background: linear-gradient(
      180deg,
      #fff3cb 0%,
      rgba(255, 234, 161, 0) 100%
    );
  }

  .bg-linear-1 {
    background: linear-gradient(
      75deg,
      #936321 -10.37%,
      #d6b25c 32.98%,
      #eccb77 51.47%,
      #d6b25c 69.95%,
      #936321 117.12%
    );
  }
  .bg-linear-2 {
    background: linear-gradient(
      180deg,
      #f6ae00 15.63%,
      #4e3700 81.25%
    ) !important;
  }
  .bg-linear-3 {
    background: linear-gradient(
      79deg,
      #936321 -46.13%,
      #d6b25c 1.91%,
      #eccb77 41.79%,
      #d6b25c 102.52%,
      #936321 135.15%
    );
  }
  .bg-linear-4 {
    background: linear-gradient(
      90deg,
      #aa7300 0%,
      #d3a300 34.47%,
      #b67000 71.81%,
      #e5b900 110.59%,
      #bb7000 143.62%
    );
  }
  .bg-linear-5 {
    background: linear-gradient(143deg, #c3922e 5.66%, #755516 95.12%);
  }
  .bg-linear-6 {
    background: linear-gradient(
      75deg,
      #936321 -69.03%,
      #d6b25c -1.39%,
      #eccb77 27.45%,
      #d6b25c 56.3%,
      #936321 129.91%
    );
  }
  .bg-linear-7 {
    background: linear-gradient(
      180deg,
      #fff3cb 0%,
      rgba(255, 234, 161, 0) 204.55%
    );
  }

  .bg-linear-8 {
    background: linear-gradient(
      57deg,
      #aa7300 -1.76%,
      #d3a300 47.23%,
      #b67000 100.3%,
      #e5b900 155.41%,
      #bb7000 202.36%
    );
  }

  .bg-linear-9 {
    background: linear-gradient(
      75deg,
      #936321 -69.03%,
      #d6b25c -1.39%,
      #eccb77 27.45%,
      #d6b25c 56.3%,
      #936321 129.91%
    );
  }
  .bg-linear-10 {
    background: radial-gradient(
      143.73% 114.62% at 50.16% -7.95%,
      #d3a300 0%,
      #796843 0.01%,
      #2d2925 100%,
      #2d2925 100%
    );
  }
  .bg-linear-11 {
    background: linear-gradient(180deg, #fff8dd 0%, #fff 100%);
  }
  .bg-linear-12 {
    background: linear-gradient(180deg, #fff 0%, #e5e5e5 100%);
  }

  .bg-linear-13 {
    background: linear-gradient(50deg, #fff 0%, #e5e5e5 100%);
  }
  .bg-linear-14 {
    background: linear-gradient(
      180deg,
      #fff3cb 0%,
      rgba(255, 234, 161, 0) 204.55%
    );
  }
  .bg-linear-15 {
    background: radial-gradient(
      143.73% 114.62% at 50.16% -7.95%,
      #d3a300 0%,
      #796843 0.01%,
      #2d2925 100%,
      #2d2925 100%
    );
  }

  .bg-linear-gray {
    background: linear-gradient(140.58deg, #e1e1e1 3.93%, #ffffff 128.71%);
  }
  .bg-linear-gray-2 {
    background: linear-gradient(316deg, #e1e1e1 3.52%, #fff 100%);
  }
  .bg-linear-gray-3 {
    background: linear-gradient(180deg, #fff 0%, #cecece 100%);
  }

  .border-linear-1 {
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      #d3a300 50%,
      #ffffff 100%
    ) !important;
  }
  .border-linear-2 {
    background: linear-gradient(180deg, #ffffff 0%, #b0b0b0 50%, #fcfcfc 100%);
  }

  .bg-gradient-right {
    background: linear-gradient(to right, #000603, rgba(255, 255, 255, 0));
  }
  .bg-gradient-left {
    background: linear-gradient(to left, #000603, rgba(255, 255, 255, 0));
  }

  .shadow-light {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25),
      1px -2px 2px 0px rgba(0, 0, 0, 0.05) inset, 0px 1px 1px 0px #fff inset;
  }
  .shadow-box-light {
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25),
      2px -4px 4px 0px rgba(0, 0, 0, 0.05) inset, 0px 2px 4px 0px #fff inset;
  }

  .shadow-1 {
    box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.15),
      0px -2px 1px 0px rgba(0, 0, 0, 0.05) inset, 0px 1px 1px 0px #fff inset;
  }

  .shadow-2 {
    box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .shadow-3 {
    /* shadown */
    box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.15),
      0px -2px 1px 0px rgba(0, 0, 0, 0.05) inset, 0px 1px 1px 0px #fff inset;
  }
  .shadow-4 {
    box-shadow: 0px 24px 40px 0px rgba(0, 0, 0, 0.05),
      0px 2px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 4px 8px -4px rgba(0, 0, 0, 0.02);
  }

  .shadow-5 {
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05),
      0px 32px 48px -8px rgba(0, 0, 0, 0.05);
  }
  .shadow-6 {
    box-shadow: 0px 0px 4px 0px rgba(132, 93, 0, 0.1),
      0px -2px 2px 0px rgba(0, 0, 0, 0.05) inset,
      0px 2px 2px 0px rgba(255, 255, 255, 0.2) inset;
  }

  .shadow-7 {
    box-shadow: 0px 0px 2px -4px rgba(0, 0, 0, 0.05),
      0px 1px 2px -8px rgba(0, 0, 0, 0.05);
  }
  .shadow-8 {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15),
      2px -4px 4px 0px rgba(0, 0, 0, 0.05) inset, 0px 2px 4px 0px #fff inset;
  }
  .shadow-9 {
    box-shadow: 0px 0px 8px -4px rgba(0, 0, 0, 0.05),
      0px 4px 4px -8px rgba(0, 0, 0, 0.05);
  }
  .shadow-10 {
    box-shadow: 0px 0px 13px 3px #eac874;
  }

  /* 11 */
  .btn-11 {
    border: none;
    color: #fff;
    overflow: hidden;
  }
  .btn-11:hover {
    text-decoration: none;
    color: #fff;
  }
  .btn-11:before {
    position: absolute;
    content: '';
    display: inline-block;
    top: -180px;
    left: 0;
    width: 30px;
    height: 100%;
    background-color: #fff;
    animation: shiny-btn1 6s ease-in-out infinite;
  }
  .btn-11:hover {
    opacity: 0.7;
  }
  .btn-11:active {
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3),
      -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
      inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
      inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
  }

  .bg-linear-16 {
    background: var(
      --Gradient-color-Radial,
      radial-gradient(
        143.73% 114.62% at 50.16% -7.95%,
        #d3a300 0%,
        #796843 0.01%,
        #2d2925 100%,
        #2d2925 100%
      )
    );
  }
  .bg-linear-17 {
    background: radial-gradient(
      373.42% 297.78% at 50.16% -191.11%,
      #d3a300 0%,
      #796843 0.01%,
      #2d2925 100%,
      #2d2925 100%
    );
  }
  .bg-linear-18 {
    background: linear-gradient(140.58deg, #e1e1e1 3.93%, #ffffff 128.71%);
  }
  .bg-linear-19 {
    background: linear-gradient(
      180deg,
      #fff3cb 0%,
      rgba(255, 234, 161, 0) 204.55%
    );
  }
  .bg-linear-20 {
    background: linear-gradient(
      180deg,
      #fff3cb 0%,
      rgba(255, 234, 161, 0) 204.55%
    );
  }
}
.module-border-wrap {
  position: relative;
  background: linear-gradient(180deg, #ffffff 0%, #b0b0b0 50%, #fcfcfc 100%);
  padding: 1px;
}

.module {
  background: #fff;
  color: white;
}

.loading-bg {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.06) 25%,
    rgba(0, 0, 0, 0.1) 37%,
    rgba(0, 0, 0, 0.06) 63%
  );

  background-size: 400% 100%;
  animation-name: skeleton;
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.hamburgerMenu div {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.hamburgerMenu > div > span {
  background: #845d00;
  border-radius: 10px;
  height: 4px;
  margin: 3px 0;
  transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.hamburgerMenu span:nth-of-type(1) {
  width: 90%;
}

.hamburgerMenu span:nth-of-type(2) {
  width: 70%;
}

.hamburgerMenu span:nth-of-type(3) {
  width: 40%;
}

.hamburgerMenu span.isActive:nth-of-type(1) {
  transform: translateY(13px) rotate(45deg) scale(0.8);
}

.hamburgerMenu span.isActive:nth-of-type(2) {
  transform: translate(0px, 6px) rotate(-45deg) scale(0.8);
}

.hamburgerMenu span.isActive:nth-of-type(3) {
  transform: translate(18px, -15px) rotate(-45deg) scale(0.8);
}

.hide-scroll-bar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none !important;
}

.hide-tap-highlight {
  -webkit-tap-highlight-color: transparent;
}
