@tailwind utilities;
/* Shine Effect */

@layer utilities {
  .hover-shine,
  .shine {
    position: relative;
    overflow: hidden;
    transition: left 0.3s, transform 0.3s;
  }

  .hover-shine:active {
    transition: left 0.2s, transform 0.3s, opacity 0.3s;
    transform: scale(0.95) !important;
  }
  .hover-shine:hover {
    transition: left 0.2s, transform 0.2s, opacity 0.2s;
    opacity: 0.85;
    transform: scale(0.98);
  }
  @media (max-width: 768px) {
    .hover-shine:hover {
      transform: scale(1);
    }
  }
  .hover-shine::before,
  .shine::before {
    position: absolute;
    top: 0;
    left: -100%;
    z-index: 999;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 100%
    );
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
  }
  .hover-shine:hover::before,
  .shine:hover::before {
    left: 125%;
    transition: left 0.5s ease-in-out, transform 0.5s ease-in-out,
      opacity 0.5s ease-in-out;
  }
  .shine::before {
    animation: shineEffect 2s infinite;
    transition: left 0.5s ease-in-out, transform 0.5s ease-in-out,
      opacity 0.5s ease-in-out;
  }

  @-webkit-keyframes shineEffect {
    100% {
      left: 125%;
    }
  }
  @keyframes shineEffect {
    100% {
      left: 125%;
    }
  }

  /* Border Effect */
  .hover-border {
    position: relative;
    transition: 0.5s;
    cursor: pointer;
    -webkit-transition: 0.5s;
  }

  .hover-border::before,
  .hover-border::after {
    width: 100%;
    height: 100%;
    z-index: 3;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    -webkit-transform: scale(0);
    transition: 0.5s;
  }

  .hover-border-item::before {
    border-bottom: 3px solid red;
    border-left: 3px solid red;
    -webkit-transform-origin: 0 100%;
  }

  .hover-border-item::after {
    border-top: 3px solid red;
    border-right: 3px solid red;
    -webkit-transform-origin: 100% 0%;
  }

  /* Border Slide Effect */
  .hover-border-slide {
    position: relative;
  }
  .hover-border-slide .border-slide-item:after {
    content: '';
    display: block;
    border-bottom: 2px solid red;
    width: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    -webkit-transition: 0.35s ease;
    transition: 0.35s ease;
  }

  .hover-border-slide .border-slide-item:hover:after {
    width: 100%;
  }

  /* Border Zoom Effect */
  .hover-zoom-container {
    overflow: hidden;
  }

  .hover-zoom-container .zoom-item img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  .hover-zoom-container .zoom-item:hover img {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }

  /* Border Zoom 2 Effect */
  .hover-zoom-2 {
    position: relative;
    top: 0;
  }

  .hover-zoom-2:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(137, 137, 137, 0.2);
    top: -3px;
    border-radius: 8px;
  }

  .hover-zoom-2:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }

  .hover-zoom-2:hover:before {
    transform: scale(2.15);
  }

  .hover-zoom-2:active {
    transform: translateY(4px);
    background: #c4f4e6;
  }

  /* Shine 2 Effect */
  .hover-shine-2 {
    background: linear-gradient(-45deg, #000 40%, #fff 0 60%, #000 0) right/300%
        100% no-repeat,
      linear-gradient(-45deg, #0000 40%, #000 0 60%, #0000 0) right/300% 100%
        no-repeat;
    -webkit-background-clip: text, padding-box;
    background-clip: text, padding-box;
  }
  .hover-shine-2:hover {
    background-position: left;
    transition: 0.8s;
  }

  .hover-cursor {
    cursor: url('/img/common/hand-cursor.svg'), default !important;
  }

  /* hover-border-2 */
  .hover-border-2 {
    position: relative;
  }

  .hover-border-2::before,
  .hover-border-2::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 0.38s ease-in-out, transform 0.35s ease-in-out;
    content: '';
    opacity: 0;
    pointer-events: none;
  }

  .hover-border-2::before {
    transform: scale3d(0, 1, 1);
    transform-origin: left top;
    border-top: 1px solid #acacac;
    border-bottom: 1px solid #acacac;
  }

  .hover-border-2::after {
    transform: scale3d(1, 0, 1);
    transform-origin: right top;
    border-right: 1px solid #acacac;
    border-left: 1px solid #acacac;
  }

  .hover-border-2:hover::before {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  .hover-border-2:hover::after {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  .hover-border-2:focus::before {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  .hover-border-2:focus::after {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  /* hover-light */
  .hover-light {
    position: relative;
  }
  .hover-light::before,
  .hover-light::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale3d(0, 0, 1);
    transition: transform 0.3s ease-out 0s;
    background: rgba(255, 255, 255, 0.1);
    content: '';
    pointer-events: none;
  }

  .hover-light::before {
    transform-origin: left top;
  }

  .hover-light::after {
    transform-origin: right bottom;
  }

  .hover-light:hover::before,
  .hover-light:hover::after {
    transform: scale3d(1, 1, 1);
  }
  .hover-light:focus::before,
  .hover-light:focus::after {
    transform: scale3d(1, 1, 1);
  }

  /* hover-dark */
  .hover-dark {
    position: relative;
  }
  .hover-dark::before,
  .hover-dark::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale3d(0, 0, 1);
    transition: transform 0.3s ease-out 0s;
    background: rgba(0, 0, 0, 0.5);
    content: '';
    pointer-events: none;
  }

  .hover-dark::before {
    transform-origin: left top;
  }

  .hover-dark::after {
    transform-origin: right bottom;
  }

  .hover-dark:hover::before,
  .hover-dark:hover::after {
    transform: scale3d(1, 1, 1);
  }
  .hover-dark:focus::before,
  .hover-dark:focus::after {
    transform: scale3d(1, 1, 1);
  }
}

.active-menu-icon {
  animation: aniGame 1.2s ease-out infinite alternate;
  -webkit-animation: aniGame 1.2s ease-out infinite alternate;
}

@keyframes aniGame {
  0% {
    transform: scaleX(1.2);
    bottom: -0.1vh;
  }

  50% {
    transform: scaleX(1);
    bottom: 1.2vh;
  }

  100% {
    transform: scaleX(1.1);
    bottom: -0.1vh;
  }
}

.active-icon:after {
  background: url('/img/menu/active/ic_GLshadow.png') no-repeat center;
  /* background: rgba(0, 0, 0, 0.2);
  clip-path: ellipse(20% 30% at 50% 50%); */
  background-size: 100% auto !important;
  display: block;
  width: 4vh;
  height: 4px;
  content: ' ';
  margin: auto;
  opacity: 0.8;
  position: absolute;
  left: calc(50% - 2vh);
  bottom: -0.2vh;
  z-index: 1;
  animation: aniShadow 1.2s ease-out infinite;
  -webkit-animation: aniShadow 1.2s ease-out infinite;
  /* filter: blur(1px); */
}

@keyframes aniShadow {
  0% {
    width: 3.6vh;
    left: calc(50% - 1.8vh);
  }

  50% {
    width: 1.4vh;
    left: calc(50% - 0.7vh);
    height: 5px;
    opacity: 0.5;
  }

  100% {
    width: 3.6vh;
    left: calc(50% - 1.8vh);
  }
}

@-webkit-keyframes aniShadow {
  0% {
    width: 3.6vh;
    left: calc(50% - 1.8vh);
  }

  50% {
    width: 1.4vh;
    left: calc(50% - 0.7vh);
    opacity: 0.5;
  }

  100% {
    width: 3.6vh;
    left: calc(50% - 1.8vh);
  }
}

.overlay-game-bottom:-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
