.swal2-popup.swal2-modal.swal2-show {
  padding: 35px 20px;
  border-radius: 8px;
}

.swal2-popup .swal2-title {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.88) !important;
  text-align: left !important;
}

.swal2-popup .swal2-actions {
  width: 100%;
}
button.swal2-confirm {
  /* margin-bottom: 10px; */
  width: 100%;
  border-radius: 24px;
  background: #d3a300;
  color: #fff;
}

button.swal2-confirm,
button.swal2-cancel {
  height: 40px;
  border-radius: 24px;
  margin-top: 4px;
}

.swal2-popup .swal2-actions,
button.swal2-confirm:focus-visible,
button.swal2-cancel:focus-visible {
  outline: none;
}

.swal2-popup .swal2-close {
  top: 24px !important;
  right: 20px !important;
}

.swal2-popup .swal2-close:focus {
  box-shadow: none !important;
}
